import { AnchorHTMLAttributes, ComponentProps, forwardRef, ReactNode } from 'react';
import NextLink from 'next/link';
import styled from 'styled-components';
import css from '@styled-system/css';
import {
  compose,
  space,
  layout,
  color,
  typography,
  TypographyProps,
  ColorProps,
  SpaceProps,
  LayoutProps,
} from 'styled-system';

import shouldOpenNewTab from 'utils/shouldOpenNewTab';

const StyledLink = styled.a(
  css({
    position: 'relative',
    borderRadius: 'xs',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'link',
    color: 'tealDark',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      outline: 0,
      boxShadow: 'buttonFocus',
    },
    '&:disabled': {
      color: 'gray20',
    },
  }),
  compose(space, layout, color, typography)
);

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  Pick<ComponentProps<typeof NextLink>, 'prefetch'> &
  TypographyProps &
  ColorProps &
  SpaceProps &
  LayoutProps & {
    href: string;
    external?: boolean;
    children: ReactNode;
  };

const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ href, external = false, prefetch, ...props }, ref) =>
    external || shouldOpenNewTab(href) ? (
      <StyledLink
        ref={ref}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      />
    ) : (
      <NextLink passHref href={href} legacyBehavior prefetch={prefetch}>
        <StyledLink ref={ref} {...props} />
      </NextLink>
    )
);

export default Link;
