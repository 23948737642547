/* eslint-ignore react/forbid-prop-types */
import PropTypes from 'prop-types';
import { Document } from '@contentful/rich-text-types';

import { headerNavType } from 'humanity/sections/headerNav/headerNav.types';

export const stepOption = PropTypes.shape({
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  fields: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.object,
  }),
});

export const stepInput = PropTypes.shape({
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  fields: PropTypes.shape({
    label: PropTypes.string,
    fieldName: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['date', 'email', 'number', 'tel', 'text', 'select']),
    placeholder: PropTypes.string,
  }),
});

export const stepHelpText = PropTypes.shape({
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  fields: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.object,
  }),
});

export const sectionStep = PropTypes.shape({
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  fields: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    isSummary: PropTypes.bool,
    content: PropTypes.object,
    hasOptions: PropTypes.bool,
    options: PropTypes.arrayOf(stepOption),
    hasInputs: PropTypes.bool,
    inputs: PropTypes.arrayOf(stepInput),
    helpText: stepHelpText,
    fieldName: PropTypes.string,
  }),
});

export const signupSection = PropTypes.shape({
  sys: PropTypes.shape({
    id: PropTypes.string,
  }),
  fields: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
});

/**
 * @deprecated
 */
export const contentfulSysType = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const contentfulRichTextType = PropTypes.shape({
  json: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.array,
    nodeType: PropTypes.oneOf(['document']),
  }),
  links: PropTypes.exact({
    entries: PropTypes.exact({
      block: PropTypes.arrayOf(PropTypes.object),
      inline: PropTypes.arrayOf(PropTypes.object),
      hyperlink: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
});

export type ContentfulRichText = {
  json: Document;
  links: {
    entries: {
      block: Array<object>;
      inline: Array<object>;
      hyperlink: Array<object>;
    };
  };
};

export const contentfulTextBlockType = PropTypes.shape({
  title: PropTypes.string,
  content: contentfulRichTextType.isRequired,
});

/**
 * @deprecated
 */
export const contentfulMediaType = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
});

/**
 * @deprecated
 */
export const contentfulImageType = PropTypes.shape({
  altText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  image: contentfulMediaType.isRequired,
  description: PropTypes.string,
});

/**
 * @deprecated
 */
export const contentfulVideoType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
});

/**
 * @deprecated
 */
export const contentfulListType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string,
  itemsCollection: PropTypes.shape({
    items: PropTypes.array,
  }),
});

/**
 * @deprecated
 */
export const contentfulLinkType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

/**
 * @deprecated
 */
export const contentfulMiniCardType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
});

/**
 * @deprecated
 */
export const contentfulCtaType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline']),
  type: PropTypes.oneOf(['link', 'button']),
});

export const contentfulDisclosuresType = PropTypes.shape({
  itemsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulTextBlockType),
  }),
});

export const contentfulIconType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const contentfulPageSectionType = PropTypes.shape({
  items: PropTypes.arrayOf(PropTypes.shape({})),
});

export const contentfulHeaderNavType = PropTypes.shape({
  mobileImage: contentfulImageType.isRequired,
  desktopImage: contentfulImageType.isRequired,
  linksCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([contentfulCtaType, contentfulLinkType, contentfulListType])
    ),
  }).isRequired,
});

export const contentfulFooterNavType = PropTypes.shape({
  contactSectionHeading: PropTypes.string.isRequired,
  contactSectionBlocksCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulRichTextType).isRequired,
  }).isRequired,
  socialMediaIconsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulIconType).isRequired,
  }).isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulListType).isRequired,
  }).isRequired,
  copyrightInfo: contentfulRichTextType.isRequired,
});

export const contentfulLayoutType = PropTypes.shape({
  headerNavigation: PropTypes.oneOfType([contentfulHeaderNavType, headerNavType])
    .isRequired,
  footerNavigation: contentfulFooterNavType.isRequired,
});

export const contentfulInputType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  type: PropTypes.oneOf(['date', 'email', 'number', 'tel', 'text', 'select']).isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  isHalfWidth: PropTypes.bool,
  optionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sys: contentfulSysType,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  }),
  validationRegex: PropTypes.string,
  validationmessage: PropTypes.string,
});

export const contentfulValuePropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  content: contentfulRichTextType.isRequired,
  icon: PropTypes.string.isRequired,
});

/**
 * @deprecated
 */
export const contentfulFormType = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    inputsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulInputType).isRequired,
    }).isRequired,
    submitButtonText: PropTypes.string.isRequired,
    formType: PropTypes.string,
  }),
});

/**
 * @deprecated
 */
export const contentfulSeoMetadataType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  noIndex: PropTypes.bool,
});

export const contentfulMastheadType = PropTypes.shape({
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'learning center',
    'payroll',
    'partner',
    'calculator',
    'guide gated',
    'guide ungated',
  ]).isRequired,
  backgroundColor: PropTypes.string,
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingHighlight: PropTypes.string,
  body: contentfulRichTextType,
  mobileImage: contentfulImageType,
  tabletImage: contentfulImageType,
  desktopImage: contentfulImageType,
  cta: PropTypes.exact({
    type: PropTypes.oneOf(['link', 'button']),
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  form: contentfulFormType,
});

export const contentfulCompactMasthead = PropTypes.shape({
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  body: contentfulRichTextType,
});

export const contentfulAuthorType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  fields: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: contentfulImageType.isRequired,
    description: PropTypes.string.isRequired,
  }),
});

export const contentfulSocialSharingType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  fields: PropTypes.shape({
    twitterText: PropTypes.string,
    twitterHashtags: PropTypes.arrayOf(PropTypes.string),
    emailSubject: PropTypes.string,
    emailBody: PropTypes.string,
  }),
});

const contentfulPaginationArticle = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }),
});

export const contentfulLeadCaptureType = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  form: contentfulFormType.isRequired,
  disclaimer: PropTypes.string.isRequired,
});

export const contentfulLearningCenterArticleType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  slug: PropTypes.string.isRequired,
  layout: contentfulLayoutType.isRequired,
  lastReviewedDate: PropTypes.string.isRequired,
  readingLength: PropTypes.number.isRequired,
  author: contentfulAuthorType.isRequired,
  featuredImage: contentfulImageType.isRequired,
  body: contentfulRichTextType.isRequired,
  socialSharingOptions: contentfulSocialSharingType.isRequired,
  previousArticle: contentfulPaginationArticle,
  nextArticle: contentfulPaginationArticle,
});

/**
 * @deprecated
 */
export const contentfulLcArticlePreview = PropTypes.shape({
  __typename: PropTypes.oneOf(['learningCenterArticle']).isRequired,
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: contentfulMediaType.isRequired,
  readingLength: PropTypes.number.isRequired,
  subcategoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        sys: contentfulSysType.isRequired,
        title: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  }),
});

export const contentfulLcCalculatorPreview = PropTypes.shape({
  __typename: PropTypes.oneOf(['learningCenterCalculator']).isRequired,
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: contentfulMediaType.isRequired,
});

export const contentfulLcGuidePreview = PropTypes.shape({
  __typename: PropTypes.oneOf(['learningCenterGuide']).isRequired,
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: contentfulMediaType.isRequired,
});

export const contentfulLcSubcategoryType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType,
  slug: PropTypes.string.isRequired,
  masthead: contentfulMastheadType,
  featuredArticlesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulLearningCenterArticleType).isRequired,
  }),
  leadCapture: contentfulLeadCaptureType,
  disclosures: PropTypes.shape({
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulRichTextType),
    }),
  }),
});

export const contentfulLcCategoryType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType,
  slug: PropTypes.string.isRequired,
  masthead: contentfulMastheadType,
  featuredArticlesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulLearningCenterArticleType).isRequired,
  }),
  watchedNewsCollection: PropTypes.arrayOf(contentfulMiniCardType).isRequired,
  subcategoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulLcSubcategoryType),
  }),
  leadCapture: contentfulLeadCaptureType,
  disclosures: PropTypes.shape({
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulRichTextType),
    }),
  }),
});

export const contentfulLcGuideType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType,
  slug: PropTypes.string.isRequired,
  masthead: contentfulMastheadType,
  categoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulLcCategoryType),
  }),
  subcategoriesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulLcSubcategoryType),
  }),
  layout: contentfulLayoutType,
  downloadUrl: PropTypes.string,
  leadCapture: contentfulLeadCaptureType,
  disclosures: PropTypes.shape({
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulRichTextType),
    }),
  }),
  thankYouPageTopCopy: contentfulRichTextType,
  thankYouPageBottomCopy: contentfulRichTextType,
  sectionsCollection: contentfulPageSectionType,
});

export const contentfulBlogArticleType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  slug: PropTypes.string.isRequired,
  layout: contentfulLayoutType.isRequired,
  lastReviewedDate: PropTypes.instanceOf(Date).isRequired,
  readingLength: PropTypes.number.isRequired,
  author: contentfulAuthorType.isRequired,
  image: contentfulMediaType.isRequired,
  body: contentfulRichTextType.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  socialSharingOptions: contentfulSocialSharingType.isRequired,
});

export const contentfulPressroomArticleType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string,
  category: PropTypes.oneOf(['Press Release', 'Media Coverage']),
  url: PropTypes.string,
  publishDate: PropTypes.string,
  publisher: PropTypes.string,
  publisherLogo: contentfulImageType,
  excerpt: PropTypes.string,
});

export const contentfulCustomerStoryType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  masthead: contentfulMastheadType.isRequired,
  publishDate: PropTypes.instanceOf(Date).isRequired,
  slug: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  companySize: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  payroll: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
  body: contentfulRichTextType.isRequired,
  excerpt: contentfulRichTextType.isRequired,
  socialSharingOptions: contentfulSocialSharingType.isRequired,
  bannerCtaText: PropTypes.string.isRequired,
  bannerCtaButton: PropTypes.string.isRequired,
  discoverMoreStoriesSection: PropTypes.object,
});

/**
 * @deprecated
 */
export const customerStoryPreviewType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  customerStoryType: PropTypes.string.isRequired,
  image: contentfulMediaType.isRequired,
  excerpt: PropTypes.string.isRequired,
});

export const contentfulCustomerStorySnapshot = PropTypes.shape({
  sys: contentfulSysType,
  industry: PropTypes.string,
  size: PropTypes.number,
  location: PropTypes.string,
  payroll: PropTypes.string,
  solution: PropTypes.string,
});

export const contentfulTestimonialType = PropTypes.shape({
  sys: contentfulSysType,
  testimonialQuote: PropTypes.string.isRequired,
  quoteAuthor: PropTypes.string.isRequired,
  authorImage: contentfulImageType.isRequired,
});

export const contentfulPricingCardType = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  baseFee: PropTypes.number.isRequired,
  employeeFee: PropTypes.number.isRequired,
  planFeatures: PropTypes.shape({
    title: PropTypes.string,
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          sys: contentfulSysType.isRequired,
          fields: PropTypes.shape({
            content: contentfulRichTextType.isRequired,
          }),
        })
      ),
    }),
  }),
  ctaLabel: PropTypes.string.isRequired,
});

export const contentfulParagraphWithImageType = PropTypes.shape({
  imageLocation: PropTypes.oneOf(['left', 'right']).isRequired,
  image: contentfulMediaType.isRequired,
  content: contentfulRichTextType.isRequired,
});

export const contentfulStatisticType = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  headingColor: PropTypes.oneOf(['teal', 'blue', 'yellow']).isRequired,
  description: contentfulRichTextType.isRequired,
});

export const contentfulValuePropWithImageType = PropTypes.shape({
  image: contentfulMediaType.isRequired,
  content: contentfulRichTextType.isRequired,
});

export const contentfulPageType = PropTypes.shape({
  seoMetadata: contentfulSeoMetadataType.isRequired,
  slug: PropTypes.string,
  masthead: contentfulMastheadType,
  sectionsCollection: contentfulPageSectionType,
  notes: PropTypes.shape({
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulTextBlockType),
    }),
  }),
  disclosures: PropTypes.shape({
    itemsCollection: PropTypes.shape({
      items: PropTypes.arrayOf(contentfulTextBlockType),
    }),
  }),
});

export const contentfulLcSearchResultsType = PropTypes.shape({
  lcArticles: PropTypes.shape({
    total: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: contentfulMediaType.isRequired,
        slug: PropTypes.string.isRequired,
        categorySlugs: PropTypes.oneOf([
          null,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]).isRequired,
      })
    ),
  }),
  lcGuides: PropTypes.shape({
    total: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: contentfulMediaType.isRequired,
        slug: PropTypes.string.isRequired,
        categorySlugs: PropTypes.oneOf([
          null,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]).isRequired,
      })
    ),
  }),
  lcCalculators: PropTypes.shape({
    total: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: contentfulMediaType.isRequired,
        slug: PropTypes.string.isRequired,
        categorySlugs: PropTypes.oneOf([
          null,
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]).isRequired,
      })
    ),
  }),
});

export const ctaSectionType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']).isRequired,
  variant: PropTypes.oneOf([
    'blue',
    'gray',
    'graySecondary',
    'embedded',
    'compactDark',
    'compactLight',
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
});

export const defaultMastheadProps = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  eyebrow: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingHighlight: PropTypes.string,
  mobileImage: contentfulMediaType.isRequired,
  desktopImage: contentfulMediaType.isRequired,
  body: contentfulRichTextType,
  cta: contentfulCtaType,
};

export const defaultMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...defaultMastheadProps,
});

export const lcMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['offwhite', 'blue', 'teal']).isRequired,
  eyebrow: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  headingHighlight: PropTypes.string.isRequired,
  body: contentfulRichTextType,
  mobileImage: contentfulMediaType.isRequired,
  tabletImage: contentfulMediaType.isRequired,
  desktopImage: contentfulMediaType.isRequired,
});

export const lcFeaturedResourcesType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showViewAll: PropTypes.bool.isRequired,
  viewAllUrl: PropTypes.string.isRequired,
  showContentType: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['default', 'compact']).isRequired,
  additionalResourcesLabel: PropTypes.string.isRequired,
  resourcesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        contentfulLcArticlePreview,
        contentfulLcCalculatorPreview,
        contentfulLcGuidePreview,
        customerStoryPreviewType,
      ])
    ),
  }),
});

/**
 * @deprecated
 */
export const simpleRichTextType = PropTypes.exact({
  json: PropTypes.exact({
    data: PropTypes.object,
    content: PropTypes.array,
    nodeType: PropTypes.oneOf(['document']),
  }),
});

export const numberedStepProps = {
  index: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  content: simpleRichTextType.isRequired,
};

export const numberedStepType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...numberedStepProps,
});

export const payrollMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  image: contentfulMediaType.isRequired,
  heading: PropTypes.string.isRequired,
  body: contentfulRichTextType.isRequired,
  form: contentfulFormType.isRequired,
});

export const sectionLargePricingCardProps = {
  topBackgroundColor: PropTypes.oneOf(['gray', 'white']).isRequired,
  bottomBackgroundColor: PropTypes.oneOf(['gray', 'white']).isRequired,
  image: contentfulMediaType.isRequired,
  heading: PropTypes.string.isRequired,
  planIntroText: PropTypes.string.isRequired,
  planMainText: PropTypes.string.isRequired,
  baseFee: PropTypes.number.isRequired,
  perEmployeeFee: PropTypes.number.isRequired,
  disclaimerText: PropTypes.string.isRequired,
  cta: contentfulCtaType.isRequired,
};

export const sectionLargePricingCardType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...sectionLargePricingCardProps,
});

export const backgroundImageMastheadProps = {
  image: contentfulMediaType.isRequired,
  heading: PropTypes.string.isRequired,
  headingHighlight: PropTypes.string,
  form: contentfulFormType.isRequired,
};

export const backgroundImageMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...backgroundImageMastheadProps,
});

export const numberedStepListProps = {
  heading: PropTypes.string,
  steps: PropTypes.arrayOf(numberedStepType).isRequired,
  cta: contentfulCtaType,
};

export const numberedStepListType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...numberedStepListProps,
});

export const payrollIntegrationType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  title: PropTypes.string.isRequired,
  image: contentfulMediaType,
  url: PropTypes.string,
});

export const payrollIntegrationsSectionProps = {
  variant: PropTypes.oneOf(['blue', 'white']),
  heading: PropTypes.string.isRequired,
  integrationLogosCollection: PropTypes.shape({
    items: PropTypes.arrayOf(payrollIntegrationType).isRequired,
  }),
  cta: contentfulCtaType.isRequired,
  completeIntegrationsList: PropTypes.shape({
    sys: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export const payrollIntegrationsSectionType = PropTypes.shape({
  ...payrollIntegrationsSectionProps,
});

export const valuePropWithImageSectionType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  backgroundColor: PropTypes.oneOf(['white', 'yellow30']).isRequired,
  valuePropsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulValuePropWithImageType),
  }).isRequired,
});

export const compactBannerType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  heading: PropTypes.string.isRequired,
  imagesCollection: PropTypes.shape({
    items: PropTypes.arrayOf(contentfulImageType).isRequired,
  }),
  backgroundColor: PropTypes.oneOf(['yellow30', 'white']),
});

export const semLandingPageType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        backgroundImageMastheadType,
        contentfulParagraphWithImageType,
        ctaSectionType,
        payrollIntegrationsSectionType,
        valuePropWithImageSectionType,
      ])
    ),
  }),
});

export const lcCollectionPageType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        lcMastheadType,
        lcFeaturedResourcesType,
        ctaSectionType,
        contentfulLeadCaptureType,
      ])
    ).isRequired,
  }).isRequired,
});

export const defaultPageType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  sectionsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        defaultMastheadType,
        ctaSectionType,
        contentfulParagraphWithImageType,
        valuePropWithImageSectionType,
        payrollIntegrationsSectionType,
      ])
    ).isRequired,
  }),
});

export const pageContentVariationWrapperType = PropTypes.shape({
  meta: PropTypes.objectOf(PropTypes.string).isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignTitle: PropTypes.string.isRequired,
  campaignKey: PropTypes.string.isRequired,
  variationsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([lcCollectionPageType, semLandingPageType])
    ),
  }).isRequired,
});

export const pageContainerType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  page: PropTypes.oneOfType([lcCollectionPageType, semLandingPageType, defaultPageType])
    .isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});

/**
 * @deprecated
 */
export const pageSettingsType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  internalTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  seoMetadata: contentfulSeoMetadataType.isRequired,
  layout: contentfulLayoutType.isRequired,
  notes: contentfulDisclosuresType,
  disclosures: contentfulDisclosuresType,
});
