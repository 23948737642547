import PropTypes from 'prop-types';
import styled, { CSSProp } from 'styled-components';
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  FlexboxProps,
} from 'styled-system';
import css, { SystemStyleObject, Theme } from '@styled-system/css';
import propTypes from '@styled-system/prop-types';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  FlexboxProps & {
    __css?: SystemStyleObject;
    css?: CSSProp;
    sx?: SystemStyleObject;
    theme?: Theme;
  };

// This is basically just to prepare us to be able to use Reflexbox once we switch to
// Emotion. The benefit will be that extending Box will apply CSS styles in a consistent,
// predictable fashion. https://rebassjs.org/reflexbox/ https://rebassjs.org/extending
const sx = (props: Props) => css(props.sx)(props.theme);
const base = (props: Props) => css(props.__css)(props.theme);

const Box = styled.div<Props>(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  base,
  sx,
  (props) => props.css,
  compose(space, layout, typography, color, flexbox)
);

export const boxProps = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.color,
  ...propTypes.flexbox,
  sx: PropTypes.object,
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Box.displayName = 'Box';

export default Box;
